import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby";
/** @jsx jsx */
import { jsx } from "theme-ui"
import Tag from "../components/tag";

const TagsPage = ({
  data: {
    allMdx: { group },
  },
}) => (
  <Layout>
    <SEO title="Home" />
    <section>
      <h1>Tags</h1>
      <section>
        {group.map(tag => (
          <Tag nome={tag.fieldValue} numeroTotal={tag.totalCount} key={tag.fieldValue}/>
        ))}
      </section>
    </section>
  </Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`